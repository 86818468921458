.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px); /* Adjusted height */
  border-left: 1px solid #333;
  background-color: #111;
  color: #ddd;
}

.messages-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  height: calc(100vh - 130px); /* Adjusted height */
}

.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 10px;
  background-color: #222; /* Darker background for messages */
}

.message.user {
  align-self: flex-end;
  background-color: #454545; /* A darker blue for user messages */
}

.message-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.message-info {
  display: flex;
  flex-direction: column;
}

.sender {
  font-weight: bold;
  color: #ffffff; /* Green color for sender to make it stand out */
}

.timestamp {
  color: #999;
  font-size: 0.75em;
}

.message-content {
  font-size: 1em;
  margin-top: 4px;
}

.input-area {
  position: fixed; /* Fix the input area to the bottom */
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 10px;
  background-color: #333; /* Dark background for the input area */
  border-top: 1px solid #444;
  z-index: 100; /* Ensure it's above other elements */
}

.input-area input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #222;
  color: #ddd;
  margin-right: 10px;
  height: 55px;
}

.input-area button {
  padding: 0px 10px;
  margin: 0px 0px 0px 10px;
  height: 55px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #00aa00;
}
